import { React, useEffect } from "react";
import NavLogged from "./NavLogged";

export default function Dashboard(
    {
        setLogged,
        setSelectedCategory,
        setSelectedSubCategory,
        setSelectedLevel,
        setSearchInput,
        setNoMatch,
        setMatch,
        swappies
    }
) {


    useEffect(() => {
        setLogged(true);
    }, []);

    return (
        <>
            <NavLogged
                setLogged={setLogged}
                setSelectedCategory={setSelectedCategory}
                setSelectedSubCategory={setSelectedSubCategory}
                setSelectedLevel={setSelectedLevel}
                setSearchInput={setSearchInput}
                setNoMatch={setNoMatch}
                setMatch={setMatch}
                swappies={swappies}
            />
        </>
    )
}
import { React } from "react";
import Logout from "../Logout";

export default function Admin() {

    return (
        <>
            <nav>
                <Logout />
            </nav>
            <h1>Admin</h1>
        </>

    )
}
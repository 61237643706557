import React, { useEffect } from "react";


export default function Notifications({ }) {

    useEffect(() => {

    }, []);

    return (
        <div>
            <h1>Notifications</h1>
        </div>
    )
}